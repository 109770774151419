import React from "react"
import moment from "moment"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

const formatTime = dateTime => {
  return moment(dateTime).format("h:mma")
}

const formatDateHeader = date => {
  return date.format("dddd")
}

const getEventData = event => {
  return {
    ...event,
    title: event.summary,
    start: moment(event.start.dateTime).toDate(),
    end: moment(event.end.dateTime).toDate(),
  }
}

const DateHeader = styled.div`
  width: calc(1% * (100/7));
  border-style: solid;
  border-color: #74b9ff;
  color: #fff;
  background-color: #74b9ff;
  text-align: center;
  font-size: calc(8px + (14 - 8) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
`
const StartingEventsContainer = styled.div`
  width: calc(1% * (100/7));
  border-style: solid;
  padding-left: 5px;
  background-color: #fff;
  min-height: 200px;
`
const LastEventsContainer = styled.div`
  width: calc(1% * (100/7));
  border-style: solid solid solid none;
  padding-left: 5px;
  background-color: #fff;
  min-height: 200px;
`
const InnerEventsContainer = styled.div`
  width: calc(1% * (100/7));
  border-style: solid solid solid none;
  padding-left: 5px;
  background-color: #fff;
  min-height: 200px;
`
const EventSummary = styled.div`
  padding-bottom: 5px;
  font-size: calc(8px + (14 - 8) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
`

const SingleWeekCalendar = ({ events, startOfWeek }) => {
  const eventData = events ? events.map(event => getEventData(event)) : []

  const buildDetails = todaysEvents =>
    todaysEvents.map(event => {
      return (
        <EventSummary>
          {`${event.summary} ${formatTime(event.start)} - ${formatTime(
            event.end
          )}`}
        </EventSummary>
      )
    })

  return (
    <Grid container>
      {[0, 1, 2, 3, 4, 5, 6].map(offset => {
        const newDate = moment(startOfWeek)
        return (
          <DateHeader>
            {formatDateHeader(newDate.add("days", offset))}
          </DateHeader>
        )
      })}
      {[0, 1, 2, 3, 4, 5, 6].map(offset => {
        const newDate = moment(startOfWeek).add("days", offset)
        const todaysEvents = eventData.filter(event =>
          newDate.isSame(event.start, "day")
        )

        if (offset === 0) {
          return (
            <StartingEventsContainer>
              <div>{newDate.format("D")}</div>
              {buildDetails(todaysEvents)}
            </StartingEventsContainer>
          )
        } else if (offset === 6) {
          return (
            <LastEventsContainer>
              <div>{newDate.format("D")}</div>
              {buildDetails(todaysEvents)}
            </LastEventsContainer>
          )
        } else {
          return (
            <InnerEventsContainer>
              <div>{newDate.format("D")}</div>
              {buildDetails(todaysEvents)}
            </InnerEventsContainer>
          )
        }
      })}
    </Grid>
  )
}

export default SingleWeekCalendar
