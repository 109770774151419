import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

const VideoContainer = styled(Grid)`
  height: 100%;
  text-align: center;
  display: inline;
  margin: 10px 24px;
`
const VideoHeading = styled.div`
  min-height: 40px;
  width: 90%;
  color: #bb2d31;
  font-size: 20px;
  margin: 0 auto;
`
const VideoDescription = styled.div`
  height: 100%;
  width: 90%;
  color: #ffff;
  position: center;
  font-size: 12px;
  margin: 0 auto 3vh auto;
`
const Video = styled.div`
  height: 200px;
  width: 300px;
  background: grey;
  display: inline-block;
  vertical-align: middle;
  margin: auto;
`
const Center = styled.div`
  margin: Auto;
  width: 90%;
`
const StyledLink = styled.a`
  color: #74b9ff;
  text-decoration: none;
`
const Description = styled.p`
  line-height: 18px;
`

const parseDescription = description => {
  const startIndex = description.indexOf("http")

  if (startIndex > -1) {
    let endIndex = description.indexOf(" ", startIndex)
    endIndex = endIndex === -1 ? description.length : endIndex
    const start = description.substring(0, startIndex)
    const url = description.substring(startIndex, endIndex)
    const end = description.substring(endIndex)

    return (
      <Description>
        {start} <StyledLink target="_blank" href={url}>{url}</StyledLink> {end}
      </Description>
    )
  }

  return <Description>{description}</Description>
}

export default ({ videoData: { title, description, videoId } }) => {
  const [formattedDescription, setFormattedDescription] = useState(undefined)

  useEffect(() => {
    setFormattedDescription(parseDescription(description))
  }, [description])

  return (
    <VideoContainer item xs={12} md={4} lg={3}>
      <Center>
        <VideoHeading>{title}</VideoHeading>
        <VideoDescription>{formattedDescription}</VideoDescription>
        <Video>
          <iframe
            title={title}
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            allowfullscreen="true"
            frameborder="0"
          ></iframe>
        </Video>
      </Center>
    </VideoContainer>
  )
}
