import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import newsPhoto from "../images/news-photo.jpg"
import NewsStory from "../components/NewsStory"
import { PageHeaderText } from './common'

const NewsContainer = styled(Grid)`
  height: 62vh;
  width: 100%;
  padding-top: 30px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${newsPhoto});
  background-size: cover;
`
const StoryContainer = styled(Grid)`
  height: 30vh;
  background: #0d0d0d;
  width: 90%;
  margin: 0 auto;
  display: flex;
  position: relative;
`
const Center = styled(Grid)`
  margin: Auto;
  height: 62vh;
  width: 90%;
  display: flex;
`

const NewsScroll = ({ event }) => (
  <NewsContainer container>
    <Grid item xs={12}>
      <PageHeaderText>What's Happening?</PageHeaderText>
      <Center>
        <StoryContainer>
          <NewsStory event={event} />>
        </StoryContainer>
      </Center>
    </Grid>
  </NewsContainer>
)

export default NewsScroll
