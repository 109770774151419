import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import servicesPhoto from "../images/services-photo.jpg"

import { SectionHeadingContainer, Blue, SectionHeader, Description, SubHeading, SubText, StyledLink } from '../components/common.js'
import VideoContainer from "./VideoContainer";

const ServicesContainer = styled(Grid)`
  height: 65vh;
  min-height: 600px;
  width: 100%;
  padding-top: 30px;

  background: linear-gradient(
    rgba(0,0,0,.5),
    rgba(0,0,0,.5)),
    url(${servicesPhoto});
  background-size: cover;
`
const Time = styled.h1`
  color: #f2f2f2;
  font-family: Raleway;
  text-align: center;
  font-size: calc(16px + (68 - 16) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin-bottom: 1%;
`
const NextVideoContainer = styled(Grid)`
  padding: 0;
`

const ServicePage = ({onlineServicesPlaylist}) => {
  return (
    <ServicesContainer container>
      <Grid item xs={12}>
        <SectionHeader>A Saint Alban's Sunday</SectionHeader>
        <Description>
          We are now open for in-person services on Sundays.
        </Description>
        {/***
          <SectionHeadingContainer>
            <SectionHeader>Christmas</SectionHeader>
          </SectionHeadingContainer>

          <SectionHeadingContainer>
          <Time>
          4:00 <Blue>PM</Blue>
          </Time>
          <SubHeading>Family Service</SubHeading>
          </SectionHeadingContainer>

          <SectionHeadingContainer>
          <Time>
          11:00 <Blue>PM</Blue>
          </Time>
          <SubHeading>Midnight Service</SubHeading>
          </SectionHeadingContainer>
        ***/}

        <SectionHeadingContainer>
          <Time>
            9:00 <Blue>AM</Blue>
          </Time>
          <SubHeading>Easter Service</SubHeading>
          <SubText>
            A family friendly service. Day care offered.
          </SubText>
        </SectionHeadingContainer>

        <SectionHeadingContainer>
          <Time>
            9:00 <Blue>AM</Blue>
          </Time>
          <SubHeading>Holy Eucharist</SubHeading>
          <SubText>
            A family friendly service. Day care offered.
          </SubText>
          <SubText>
            <StyledLink href={onlineServicesPlaylist.slug}>
              Past Services
            </StyledLink>
          </SubText>
        </SectionHeadingContainer>

        {/*<NextVideoContainer>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <VideoContainer videoData={onlineServicesPlaylist.items[0]} />*/}
        {/*  </Grid>*/}
        {/*</NextVideoContainer>*/}
      </Grid>
    </ServicesContainer>
  )
}

export default ServicePage