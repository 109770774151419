import React from "react"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  float: left;
`

const Callouts = styled.ul`
  list-style-type: none;
`
const Callout = styled.li`
  display: inline-block;
  /* Real styles */
  position: relative;
  left: -53px;
  top: -105px;
  width: 100px;
  height: 75px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  box-shadow: 0 0 10px #c1c1c1;
  :before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 23px;
    bottom: -42px;
    border: 10px solid transparent;
    border-top: 32px solid rgb(193, 193, 193); /* IE8 Fallback */
    border-top: 32px solid rgba(193, 193, 193, 0.5);
    z-index: 2;
  }
  :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 25px;
    bottom: -31px;
    border: 8px solid transparent;
    border-top: 24px solid #fff;
    z-index: 3;
  }
`

const AnyReactComponent = ({ text }) => (
  <Callouts>
    <Callout>{text}</Callout>
  </Callouts>
)

export default ({
  center,
  zoom = 15,
}) => {
  return (
    // Important! Always set the container height explicitly
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_GOOGLE_API_KEY}` }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {center && (
          <AnyReactComponent
            lat={center.lat}
            lng={center.lng}
            text="St. Albans"
          />
        )}
      </GoogleMapReact>
    </MapContainer>
  )
}
