import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Homepage from "../components/homepage"
import Services from "../components/servicepage"
import CalendarScroll from "../components/CalendarScroll"
import NewsScroll from "../components/newsscroll"
import Contact from "../components/contact"
import Layout from "../components/layout"

const SectionContainer = styled(Grid)`
  margin: 0 auto;
  padding: 0;
`

export default ({ data }) => {
  const calendarEvents = data && data.allGoogleCalendarEvent.nodes
  const event = data && data.allMdx.nodes[0]
  const location =
    data && data.allLocationData && data.allLocationData.nodes[0].results[0].geometry.location
  const onlineServicesPlaylist = data.allYoutubePlaylist.nodes[0]

  return (
    <Layout container>
      <SEO title="Home" />
      <Grid item xs={12}>
        <Homepage />
      </Grid>
      <SectionContainer item xs={12} id="services">
        <Services onlineServicesPlaylist={onlineServicesPlaylist} />
      </SectionContainer>
      <SectionContainer item xs={12} id="events">
        <CalendarScroll events={calendarEvents} />
      </SectionContainer>
      <SectionContainer item xs={12} id="news">
        <NewsScroll event={event} />
      </SectionContainer>
      <SectionContainer item xs={12} id="contact">
        <Contact location={location} />
      </SectionContainer>
    </Layout>
  )
}

export const query = graphql`
  query mainPageQuery {
    allLocationData {
      nodes {
        results {
          geometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 1) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          name
          title
          path
          subheading
        }
        fields { 
          slug 
        }
        excerpt
        body
      }
    }
    allGoogleCalendarEvent {
      nodes {
        location
        slug
        start {
          dateTime
        }
        summary
        status
        end {
          dateTime
        }
      }
    }
    allYoutubePlaylist(
      filter: { title: { eq: "Online Services" } }
      sort: { fields: items___position, order: DESC }
    ) {
      nodes {
        id
        channelTitle
        description
        slug
        title
        items {
          id
          position
          title
          videoId
          videoPublishedAt
          description
        }
      }
    }
  }
`
