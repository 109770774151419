import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import calendarPhoto from "../images/callendar-photo.jpg"
import moment from "moment"
import SingleWeekCalendar from "./SingleWeekCalendar"
import { PageHeaderText } from './common'

const PageContainer = styled(Grid)`
  height: 60vh;
  width: 100%;
  padding-top: 30px;
  background: linear-gradient(
      180deg,
      rgba(51, 121, 200, 0.85) 35.42%,
      rgba(255, 255, 255, 0) 74.48%
    ),
    url(${calendarPhoto});
  background-size: cover;
`
const CalendarContainer = styled(Grid)`
  padding: 0 10px;
`

export default ({ events }) => {
  const startDateTime = moment().startOf("week")
  const endDateTime = moment().endOf("week")
  
  return (
    <PageContainer container>
      <Grid style={{paddingBottom: 30}} item xs={12}>
        <PageHeaderText>This Week</PageHeaderText>
      </Grid>

      <CalendarContainer item xs={12}>
        <SingleWeekCalendar
          events={events}
          startOfWeek={startDateTime}
          endOfWeek={endDateTime}
        />
      </CalendarContainer>
    </PageContainer>
  )
}
