import React from "react"
import styled from "styled-components"
import ArrowDownIcon from "@material-ui/icons/ArrowDropDownCircle"
import Grid from "@material-ui/core/Grid"
import { navigate } from "@reach/router"

const MainContainer = styled(Grid)`
  padding-top: 75px;
h  height: calc(100vh - 75px);
  color: #fff;
`

const CenterContent = styled(Grid)`
  height: calc(100vh - 215px);
`

const StyledIcon = styled(ArrowDownIcon)`
  font-size: 90px;
  color: "white";
  bottom: 5%;
`
const ScrollBoxes = styled(Grid)`
  position: centered;
  text-align: center;
  width: 100%;
  margin-top: 4%;
`

const ScrollBox = styled.div`
  display: inline-block;
  text-align: center;
  width: auto;
  padding: 2vh 2vw;
  background: rgba(51, 51, 51, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px)/(1600 - 300)));
`

const CenteredHeader = styled.h1`
  width: 75%;
  margin-left: 12%;
  text-align: left;
  font-family: Quicksand;

  font-style: normal;

  martin-top: 20px;
  font-size: calc(18px + (74 - 18) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px)/(1600 - 300)));
`

const Content = styled.h3`
  width: 75%;
  margin-left: 12%;

  text-align: left;
  font-family: raleway;
  font-style: normal;
  font-weight: bold;

  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));

  color: #f2f2f2;
`

const ButtonContainer = styled(Grid)`
  text-align: center;
  width: 93%;
  position: absolute;

  bottom: 5%;
`

const scrollToPage2 = () => {
  const element = document.getElementById("services")
  element.scrollTo(50, 50)
}

const Homepage = () => (
  <MainContainer container>
    <Grid item xs={12}>
      <CenteredHeader>Family, Friends, and Faith!</CenteredHeader>
    </Grid>

    <CenterContent item xs={12}>
      <Content>
        No matter who you are; gender, race, social or economic background,
        sexual orientation or marital status; God has a deep and abiding love
        for you. So, whoever you are or wherever you may be on you spiritual
        journey; we invite you to find a place of welcome, fellowship and
        spiritual growth here at St. Alban’s. We are grateful for your visit to
        our website and invite you to worship with us soon.
      </Content>

      <ScrollBoxes item xs={12}>
        <ScrollBox onClick={() => navigate("#services")}>Services</ScrollBox>
        <ScrollBox onClick={() => navigate("#events")}>Calendar</ScrollBox>
        <ScrollBox onClick={() => navigate("#news")}>
          What's Happening?
        </ScrollBox>
        <ScrollBox onClick={() => navigate("#contact")}>
          Contact Us
        </ScrollBox>
      </ScrollBoxes>

      <ButtonContainer item xs={12}>
        <StyledIcon onClick={scrollToPage2} />
      </ButtonContainer>
    </CenterContent>
  </MainContainer>
)

export default Homepage
