import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import ContactPhoto from "../images/contact-photo.jpg"
import GoogleMap from "./GoogleMap"
import { PageHeaderContainer, PageHeaderText, Info } from './common'

const ContactContainer = styled(Grid)`
  height: 85vh;
  width: 100%;
  padding-top: 30px;
  background: linear-gradient(
      180deg,
      rgba(51, 121, 200, 0.85) 35.42%,
      rgba(255, 255, 255, 0) 74.48%
    ),
    url(${ContactPhoto});
  background-size: cover;
  display: flex;
`
const InfoHolder = styled(Grid)`
  text-align: center;
  height: 210px;
`
const Center = styled(Grid)`
  margin: Auto;
  height: 75vh;
  width: 90%;
  display: flex;
`
const ContactInfo = styled.div`
  margin: 50px auto 0 auto;
  text-align: center;
`
const Placeholder = styled.div`
  float: left;
  height: 100%;
  width: 100%;
`

export default ({ location }) => {
  return (
    <ContactContainer>
      <Center>
        <Grid container>
          <Grid item xs={6} lg={7}>
            <PageHeaderContainer>
              <PageHeaderText>Contact Us</PageHeaderText>
            </PageHeaderContainer>
            <ContactInfo>
              <InfoHolder>
                <Info>
                  W239 N6440 Maple Avenue
                  <br />
                  Sussex, Wisconsin 53089
                </Info>
                <Info>(262) 246-4430</Info>
              </InfoHolder>
            </ContactInfo>
          </Grid>

          <Grid item xs={6} lg={5}>
            <Placeholder>
              <GoogleMap center={location} zoom={15} />
            </Placeholder>
          </Grid>
        </Grid>
      </Center>
    </ContactContainer>
  )
}
